import axios from 'axios'
import { UserAuth } from '../context/AuthContext'
import { auth } from './firebaseConfig'
import { getIdToken, onAuthStateChanged, signOut } from 'firebase/auth'
import { store } from '../redux/store'
import { signOut as reduxSignout, saveFirebaseLoginData } from '../redux/authSlice'
import { updateToken } from '../redux/authSlice'
import moment from 'moment/moment'
import AuthAPI from './authAPI'

const getUserIdToken = async () => {
  return new Promise((resolve) => {
    const unsub = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // console.log(user)
        const token = await getIdToken(user)
        resolve(token)
      } else {
        console.log('User not logged in')
        resolve(null)
      }
      unsub()
    })
  })
}

const devConfig = {
  baseURL: 'https://katadata-crm.my.id/',
  headers: {
    //  Authorization: `<Your Auth Token>`,
    'Content-Type': 'application/json',
    timeout: 1000,
  },
}

const localConfig = {
  baseURL: 'http://localhost:3001/',
  headers: {
    //  Authorization: `<Your Auth Token>`,
    'Content-Type': 'application/json',
    timeout: 1000,
  },
}
const dummyConfig = {
  baseURL: 'https://dummyjson.com',
  header: {
    //  Authorization: `<Your Auth Token>`,
    'Content-Type': 'application/json',
    timeout: 1000,
  },
}

const errorHandler = (error) => {
  const statusCode = error.response?.status

  // logging only errors that are not 401
  if (statusCode && statusCode !== 401) {
    console.error(error)
  }

  return Promise.reject(error)
}

const devAxios = axios.create(devConfig)
// const devAxios = axios.create(localConfig)
const dummyAxios = axios.create(dummyConfig)

devAxios.interceptors.request.use(async (request) => {
  // if (!store.getState().auth.firebaseUser.lastLoggedIn) {
  //   auth.signOut()
  //   store.dispatch(reduxSignout())
  // }
  // // console.log(moment().diff(moment(store.getState().auth.firebaseUser.lastRefreshToken).format(), 'minutes'))
  // if (moment().diff(moment(store.getState().auth.firebaseUser.lastRefreshToken).format(), 'minutes') > 30) {
  //   if (store.getState().auth.firebaseUser.isRefreshing) {
  //     new Promise((resolve) => setTimeout(resolve, 1000))
  //   } else {
  //     var refreshedToken = await AuthAPI.refreshToken().then((res) => {
  //       return res.access_token
  //     })
  //     store.dispatch(
  //       saveFirebaseLoginData({
  //         ...store.getState().auth.firebaseUser,
  //         accessToken: refreshedToken,
  //         lastRefreshToken: moment().format(),
  //         isRefreshing: false,
  //       })
  //     )
  //   }
  // }
  // console.log(moment(store.getState().auth.firebaseUser.lastLoggedIn).diff(moment(), 'days'))
  // console.log(moment(new Date('2023-09-04T10:00:10+07:00')).startOf('day').format())
  // console.log(moment(store.getState().auth.firebaseUser.lastLoggedIn).startOf('day').diff(moment(), 'days'))

  if (moment(store.getState().auth.firebaseUser.lastLoggedIn).startOf('day').diff(moment(), 'days') !== 0) {
    signOut(auth)
    store.dispatch(reduxSignout())
  }

  var token = await getUserIdToken()
  request.headers.Authorization = `Bearer ${token}`
  return request
})

devAxios.interceptors.response.use(undefined, (response) => {
  // console.log(response.response)
  if (response.response?.status === 401 && response.response.data.error_code === 'auth/argument-error') {
    signOut(auth)
    window.location = '/login'
  }
  return errorHandler(response)
})

export { devAxios, dummyAxios }
